import {useState, useRef} from 'react';
import { useForm } from 'react-hook-form';
import { next } from '../../assets';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContactForm = () => {
  const [name, setEname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = data => {
      setIsLoading(true);
      toast.info('Sending your message...');

        const serviceID = 'service_iufqd4p';
        const templateID = 'template_zkv6wd3';
        const userID = "PCLrIrxhrpxQEUATw";

        // TESTING ID  
        // const serviceID = 'service_l1odmmu';
        // const templateID = 'template_9u14jfa';
        // const userID = "_cD4wsZB3NXxWVfZF";

        const formData = {
          name: data.fullName,
          email: data.email,
          subject: data.subject,
          message: data.message,
      }

      emailjs.send(serviceID, templateID, formData, userID)
      .then(() => {
        setIsLoading(false);
        toast.dismiss();
        toast.success('Email sent successfully!');
      })
      .catch(() => {
        setIsLoading(false);
        toast.dismiss();
        toast.error('Failed to send. Please try again later.');
      });
    console.log(data);
  };

  return (
    <div className='GetInTOuchBg px-lg-3'>

      <div className="page-container row px-3 mt-5 wow fadeInUp" data-wow-delay="0.5s">
        <div className="contact-form-container col-12">
          <div className="contact-header text-center">
            <h2>Get in Touch</h2>
            <p>
              <i className="fas fa-envelope"></i><span className='ms-lg-2'>contact@vikgol.com</span>
              &nbsp;&nbsp;
              <i className="fas fa-phone ms-lg-5"></i> (011) 6543 8974 210
            </p>
          </div>

        </div>

        <div className="container p-lg-5 form_box col-11">
          <form onSubmit={handleSubmit(onSubmit)} className="form-group">
            <div className="row mb-3">
              <div className="col-md-6">
                <label className='mt-3' htmlFor="fullName">Full Name *</label>
                <input
                  id="fullName"
                  type="text"
                  className={`form-control ${errors.fullName ? 'is-invalid' : ''} mt-3`}
                  placeholder="Enter your full name"
                  {...register('fullName', { required: 'Full Name is required' })}
                />
                {errors.fullName && <div className="invalid-feedback">{errors.fullName.message}</div>}
              </div>
              <div className="col-md-6">
                <label className='mt-3' htmlFor="email">Your Email *</label>
                <input
                  id="email"
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''} mt-3`}
                  placeholder="Enter your email"
                  {...register('email', { required: 'Email is required' })}
                />
                {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className='mt-3' htmlFor="company">Company *</label>
                <input
                  id="company"
                  type="text"
                  className={`form-control ${errors.company ? 'is-invalid' : ''} mt-3`}
                  placeholder="Enter your company name"
                  {...register('company', { required: 'Company is required' })}
                />
                {errors.company && <div className="invalid-feedback">{errors.company.message}</div>}
              </div>
              <div className="col-md-6">
                <label className='mt-3' htmlFor="subject">Subject *</label>
                <select
                  id="subject"
                  className={`form-control ${errors.subject ? 'is-invalid' : ''} mt-3 p-0`}
                  {...register('subject', { required: 'Subject is required' })}
                >
                  <option value="">Select a subject</option>
                  <option value="Web application development">Web application development</option>
                  <option value="Mobile application development">Mobile application development</option>
                  <option value="Enterprise application development">Enterprise application development</option>
                  <option value="DevOps Service">DevOps Service</option>
                  <option value="Testing Service">Testing Service</option>
                  <option value="Digital Marketing Service">Digital Marketing Service</option>
                  <option value="UI/UX Service">UI/UX Service</option>
                  <option value="Others">Others</option>
                </select>

                {errors.subject && <div className="invalid-feedback">{errors.subject.message}</div>}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label className='mt-3' htmlFor="message">Message *</label>
                <textarea
                  id="message"
                  className={`form-control ${errors.message ? 'is-invalid' : ''} mt-3`}
                  placeholder="Enter your message"
                  {...register('message', { required: 'Message is required' })}
                />
                {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
              </div>
            </div>
            <div className="text-left mt-5">
              <button type='submit' className="btn btn-primary btn-visonimpnew  btn-lg"><span>Submit</span> <img className='mt-1 imgNext' src={next} alt="" /></button>
            </div>
          </form>
        </div>

      </div>

    </div>

  );
}

export default ContactForm;