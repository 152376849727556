import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import { Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo9, Logo10 } from '../assets';

import './home/homePage.scss'
// Import other logos similarly

const logos = [
  { src: Logo1, alt: 'Sorrentina' },
  { src: Logo2, alt: 'Another' },
  { src: Logo3, alt: 'Another' },
  { src: Logo5, alt: 'Another' },
  { src: Logo6, alt: 'Another' },
  { src: Logo4, alt: 'Another' },
  { src: Logo8, alt: 'Another' },
  { src: Logo7, alt: 'Another' },
  { src: Logo9, alt: 'Another' },
  { src:Logo10, alt: 'Another' },

 
  
  // Add other logos here
];

const LogosSlider = () => {
  const [direction, setDirection] = useState('left');
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setDirection(prevDirection => (prevDirection === 'left' ? 'right' : 'left'));
    }, 3000); // Change direction every 3 seconds

    return () => clearInterval(timer);
  }, []);

  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:0,
    cssEase: "linear",
    
    responsive
    : [
          {
    breakpoint
    : 768,
    // Mobile breakpoint
    settings
    : {
    slidesToShow
    : 3,
    slidesToScroll
    : 1,
            }
          }
        ]
    
    



  };

  return (

    <div className='w-100 mt-lg-5 ' >

<div className="carousel-container wow fadeInUp" data-wow-delay ="0.5s">
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index}>
            <img src={logo.src} alt={logo.alt} style={{ maxWidth: '100%', height: '60px' }} />
          </div>
        ))}
      </Slider>
    </div>

    </div>
    
  );
};

export default LogosSlider;